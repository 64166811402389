<template>
	<div>
		<template v-if="!loading.overall">
			<v-container>
				<SettingsGear :showIcon="true" />
				<div style="height: 15vh">
					<div
						style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 2vh;
            "
					>
						<img
							src="@/assets/logos/dime_logo_gold.png"
							style="height: 7em"
						/>
					</div>
					<!-- Content -->
					<div
						style="
              height: 2vh;
              color: white;
              text-align: center;
              margin-top: 2vh;
            "
						class="text-uppercase text-white fw-bold d-block"
					>
						{{ XTankOrBlow }}
					</div>
					<div style="height: 64vh; overflow: hidden">
						<!-- <div
              style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: flex-end;
          "
            >
              <img
                src="@/assets/logos/dime_thinkhigher.png"
                style="height: 2.5em"
              />
            </div> -->
						<!-- Content -->
						<div
							v-show="isConnected && isInsert"
							style="
                display: flex;
                flex-direction: row;
                justify-content: center;
                height: 81%;
                overflow-y: scroll;
                overflow-x: hidden;
              "
						>
							<form style="width: 100%; margin-top: 3vh">
								<div
									class="mb-3"
									v-for="(preset, presetIndex) in presetTypes"
									:key="presetIndex"
									@click="traverse('preset', presetIndex)"
									style="display: flex; justify-content: center"
								>
									<div
										class="py-2"
										:style="
											`
                    width:100%;
                  border: ${
						preset.active ? `none` : `2px solid ${getAccentColor}`
					};
                  background-color: ${preset.active ? getActionColor : ''};
                  border-radius: 30px;
                `
										"
									>
										<div class="row">
											<div class="col col-3 item-center">
												<img
													:src="preset.icon"
													:style="
														`height: 2em; margin-left: 1.25em; margin-top: 0.5em`
													"
												/>
											</div>
											<div class="col col-5">
												<span
													class="text-uppercase text-white fw-bold d-block pt-2"
													>{{
														preset.pasteTypeName
													}}</span
												>
											</div>
											<div
												class="col"
												style="text-align: right; margin-right: 20px"
											>
												<span
													class="text-white fw-bold"
													style="font-size: 1.7em"
													>{{
														isVoltage ? (parseFloat(preset.heatingModeList[0].temperature.split(',')[0]) / 1000).toFixed(2)  : (preset.heatingModeList[0].temperature.split(',')[0])
													}}<span>{{ isVoltage ? 'V' : '&#176;' }}</span></span
												>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div
							class="presets-button text-uppercase"
							v-show="
								!presetsIsEmpty &&
									isInsert &&
									isConnected &&
									isCanEdit
							"
							@click="onClickEditPresets"
						>
							edit presets
						</div>
						<div v-show="false">{{ podId }}</div>
					</div>
					<Footer />
				</div>
			</v-container>
		</template>
		<template v-else> Loading...</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
// Assets
import StoreType from '../store/bluetooth/StoreType'
import cartridge from '../api/cartridge'
import { Toast } from 'vant'

export default {
	name: 'presets',
	data() {
		return {
			loading: {
				overall: false,
			},
			response: {
				presetTypes: [],
			},
			payload: {},
			lastPod: this.$store.state.bluetooth.podId,
			lastPodPresetTypes: [],
			isCanEdit: false,
			canShowPreset: true,
		}
	},
	created() {},
	mounted() {
		this.setup()
	},
	methods: {
		/* API Calls */
		// GET
		// POST
		/* Main */
		async setup() {
			// await this.setLoadingState(true);
			// Methods
			this.showLoading('Loading...')
			let presetTypes = await cartridge.presetTypes(
				this.$store.state.bluetooth.podId
			)
			this.hideLoading()
			this.$store.commit(
				StoreType.COMMIT_TYPE.STATE_PRESET_FROM_SERVER,
				presetTypes
			)

			this.isCanEdit = false
			//this.canShowPreset = true
		},
		traverse(action, params) {
			switch (action) {
				case 'preset':
					this.setActivePreset(params)
					break
			}
		},
		setActivePreset(index) {
			let presetTypes = this.presetTypes
			presetTypes.forEach((type) => (type['active'] = false))
			presetTypes[index]['active'] = true
			this.isCanEdit = presetTypes[index].isCanEdit

			this.$store.commit(
				StoreType.COMMIT_TYPE.STATE_PRESET_FROM_SERVER,
				presetTypes
			)
			this.$store.commit(
				StoreType.COMMIT_TYPE.STATE_PRESET_IS_CHANGED,
				true
			)
		},

		/* Misc */
		async setLoadingState(loadingState) {
			this.loading.overall = loadingState
		},
		onClickEditPresets() {
			let result = this.presetTypes.filter((item) => item.active)
			if (result.length === 0) {
				this.$showTips({ msg: 'Please select preset' })
				// Toast.fail("Please select preset");
				return
			}
			this.$router.push({
				name: 'editPresets',
			})
		},
	},

	watch: {
		isInsert(newValue, oldValue) {
			if (!newValue) {
				//拔出烟弹
				this.canShowPreset = false
				this.isCanEdit = false
				this.lastPod = this.podId
				this.lastPodPresetTypes = this.presetTypes
				this.$store.commit(
					StoreType.COMMIT_TYPE.STATE_PRESET_FROM_SERVER,
					[]
				) //更换了不同的烟弹

				this.$showTips({
					msg: 'Please Insert Cartridge.',
				})
			} else {
				//插入烟弹
				if (this.lastPod === this.podId) {
					this.$store.commit(
						StoreType.COMMIT_TYPE.STATE_PRESET_FROM_SERVER,
						this.lastPodPresetTypes
					) //更换了不同的烟弹

					this.canShowPreset = true
				}
			}
		},
		//烟弹改变了
		podId(newValue, oldValue) {
			if (this.lastPod !== newValue) {
				this.setup()
			} else {
				if (
					this.lastPodPresetTypes &&
					this.lastPodPresetTypes.length === 0
				) {
					this.setup()
					return
				}
				this.$store.commit(
					StoreType.COMMIT_TYPE.STATE_PRESET_FROM_SERVER,
					this.lastPodPresetTypes
				) //更换了不同的烟弹

				this.canShowPreset = true
			}
		},

		presetTypes(newValue, oldValue) {
			let selectedPreset = this.presetTypes.filter((item) => item.active)
			this.isCanEdit =
				this.presetTypes &&
				selectedPreset.length > 0 &&
				selectedPreset[0].isCanEdit
		},
		isConnected(newValue, oldValue) {
			if (!newValue) {
				console.log(this.$route.name)
				if (this.$route.name == 'presets') {
					this.$showTips({
						msg:
							'Connect your device and attachment to see the presets for it.',
						duration: 3000,
					})

					setTimeout(() => {
						this.$router.replace({ name: 'device' })
					}, 3200)
				}
			}
		},
	},

	computed: {
		...mapGetters([
			'getMainColor',
			'getAccentColor',
			'getActionColor',
			'presetsIsEmpty',
			'presetTypes',
			'isInsert',
			'isConnected',
			'podId',
			'isPaste',
				'isVoltage',
		]),

		XTankOrBlow() {
			return this.isConnected && this.isInsert
				? this.isPaste
					? 'X - BOWL Presets'
					: (this.isVoltage ? 'X - NIC Presets' : 'X - TANK Presets')
				: ''
		},
	},
}
</script>

<style lang="less" scoped>
.item-center {
	display: flex;
	justify-content: center;
}

.presets-button {
	font-family: 'Roboto', sans-serif;
	color: #fff;
	font-size: calc(1.8rem + 1.5vw);
	font-weight: bold;
	text-align: center;
	// background: #ffab00;
	border: 2px solid #d9a845;
	border-radius: 40px;
	height: 59px;
	line-height: 59px;
	margin-top: 22px;
}

.presets-button:active {
	background: #ffab00;
}
</style>
